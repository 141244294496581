// module imports
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// local imports
import { HomepageLayout } from "../Home/Home";
import TimecodeCalculator from "../TimecodeCalculator/TimecodeCalculator";

// css imports
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route exact path="/" component={HomepageLayout} />
                    <Route path="/tools/timecode-calculator" component={TimecodeCalculator} />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
