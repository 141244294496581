import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Menu,
    Image,
    Dropdown,
    Segment,
    Grid,
    Input,
    Button,
    Select,
    Header,
    List,
    Icon
} from 'semantic-ui-react';
import logo from "../../images/logo light horiz.png";

const frameRateOptions = [
    { key: '23.98', text: '23.98 fps', value: 23.98 },
    { key: '24', text: '24 fps', value: 24 },
    { key: '25', text: '25 fps', value: 25 },
    { key: '29.97', text: '29.97 fps', value: 29.97 },
    { key: '30', text: '30 fps', value: 30 },
    { key: '50', text: '50 fps', value: 50 },
    { key: '59.94', text: '59.94 fps', value: 59.94 },
    { key: '60', text: '60 fps', value: 60 },
];

// Validates and formats timecode string
const validateTimecode = (tc) => {
    // Remove any non-digit or non-colon characters
    tc = tc.replace(/[^\d:]/g, '');

    // Split into components
    const parts = tc.split(':');
    const nums = parts.map(p => parseInt(p) || 0);

    // Ensure we have 4 parts
    while (nums.length < 4) nums.push(0);

    // Format each number to 2 digits
    return nums.map(n => n.toString().padStart(2, '0')).join(':');
};

// Convert timecode to total frames
const timecodeToFrames = (tc, fps) => {
    const [hh, mm, ss, ff] = tc.split(':').map(Number);
    // Calculate total seconds
    const totalSeconds = (hh * 3600) + (mm * 60) + ss;
    // Convert to frames and add remaining frames
    return Math.round((totalSeconds * fps) + ff);
};

// Convert frames to timecode
const framesToTimecode = (totalFrames, fps) => {
    // Calculate time parts
    const totalSeconds = Math.floor(totalFrames / fps);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const frames = Math.floor(totalFrames % fps);

    // Format with padding
    return [hours, minutes, seconds, frames]
        .map(n => n.toString().padStart(2, '0'))
        .join(':');
};

const baseButtonStyle = {
    backgroundColor: '#2d2d2d',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    margin: '0.05em',
    fontSize: '1.5em',
    height: '3.2em',
    transition: 'all 0.2s ease',
    fontWeight: '500',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#3d3d3d',
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.3)'
    },
    '&:active': {
        transform: 'translateY(1px)',
        boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
        backgroundColor: '#2a2a2a'
    }
};

const calculatorStyles = {
    displayContainer: {
        position: 'relative',
        marginBottom: '0.5em'
    },
    display: {
        backgroundColor: '#000',
        color: '#fff',
        fontFamily: 'monospace',
        fontSize: '1.8em',
        padding: '0.3em 0.3em 0.3em 2.5em',
        margin: '0.3em 0',
        borderRadius: '8px',
        border: '1px solid #333',
        position: 'relative',
        boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.5)',
        minHeight: '2.5em',
        transition: 'all 0.3s ease',
        outline: 'none'
    },
    copyButton: {
        position: 'absolute',
        left: '0.5em',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: 'transparent',
        color: '#268bd2',
        border: 'none',
        zIndex: 2,
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        padding: '0.4em',
        opacity: 0.8,
        fontSize: '1em',
        width: '1.5em',
        height: '1.5em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            color: '#2aa198',
            opacity: 1,
            transform: 'translateY(-50%) scale(1.05)'
        }
    },
    operationDisplay: {
        position: 'absolute',
        top: '0.2em',
        right: '0.5em',
        fontSize: '0.6em',
        color: '#666',
        fontFamily: 'monospace',
        textAlign: 'right',
        width: '100%',
        padding: '0 0.5em',
        boxSizing: 'border-box',
        height: '1em',
        overflow: 'hidden'
    },
    secondaryDisplay: {
        backgroundColor: '#000',
        color: '#666',
        fontFamily: 'monospace',
        fontSize: '1em',
        padding: '0.3em',
        margin: '0.2em 0',
        borderRadius: '8px',
        boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        ...baseButtonStyle
    },
    operatorButton: {
        ...baseButtonStyle,
        backgroundColor: '#1a1a1a',
        color: '#268bd2',
        fontWeight: 'bold',
        fontSize: '1.8em'
    },
    functionButton: {
        ...baseButtonStyle,
        backgroundColor: '#1a1a1a',
        color: '#dc322f',
        fontWeight: 'bold'
    },
    modeToggleButton: {
        ...baseButtonStyle,
        backgroundColor: '#1a1a1a',
        padding: 0,
        flexDirection: 'column',
        height: '3.2em'
    },
    modeLabel: {
        fontSize: '0.8em',
        color: '#666',
        marginBottom: '0.2em'
    },
    modeValue: {
        fontSize: '1em',
        fontWeight: 'bold'
    },
    equalsButton: {
        ...baseButtonStyle,
        backgroundColor: '#1a1a1a',
        color: '#268bd2',
        fontWeight: 'bold',
        fontSize: '1.4em',
        height: 'calc(6.4em + 0.4em)',
        margin: '0.1em'
    },
    input: {
        backgroundColor: 'transparent',
        color: '#fff',
        fontFamily: 'monospace',
        fontSize: '1em',
        width: '100%',
        border: 'none',
        padding: '0.3em',
        margin: '0.8em 0 0 0',
        textAlign: 'right',
        position: 'relative',
        zIndex: 1,
        caretColor: 'transparent',
        outline: 'none'
    }
};

function TimecodeCalculator() {
    const history = useHistory();
    const [frameRate, setFrameRate] = useState(25);
    const [timecode1, setTimecode1] = useState('00:00:00:00');
    const [timecode2, setTimecode2] = useState('00:00:00:00');
    const [frames, setFrames] = useState('0');
    const [result, setResult] = useState('00:00:00:00');
    const [activeInput, setActiveInput] = useState(null);
    const [operation, setOperation] = useState(null);
    const [isNewInput, setIsNewInput] = useState(true);
    const [displayOperation, setDisplayOperation] = useState('');
    const [runningTotal, setRunningTotal] = useState(null);
    const [isFrameMode, setIsFrameMode] = useState(false);
    const inputRef = useRef(null);
    const [copySuccess, setCopySuccess] = useState(false);

    const handleClear = () => {
        setTimecode1('00:00:00:00');
        setTimecode2('00:00:00:00');
        setResult('00:00:00:00');
        setFrames('0');
        setOperation(null);
        setRunningTotal(null);
        setDisplayOperation('');
        setIsNewInput(true);
        // Set focus and active input after clearing
        inputRef.current?.focus();
        setActiveInput('tc1');
    };

    const handleFrameModeToggle = () => {
        if (isFrameMode) {
            // Converting from frames to timecode
            const tc = framesToTimecode(parseInt(frames) || 0, frameRate);
            setTimecode1(tc);
            setResult(tc);
            if (operation && runningTotal) {
                // If in middle of calculation, convert running total to timecode
                setRunningTotal(framesToTimecode(parseInt(runningTotal) || 0, frameRate));
            }
        } else {
            // Converting from timecode to frames
            const frm = timecodeToFrames(timecode1, frameRate);
            setFrames(frm.toString());
            setResult(frm.toString());
            if (operation && runningTotal) {
                // If in middle of calculation, convert running total to frames
                setRunningTotal(timecodeToFrames(runningTotal, frameRate).toString());
            }
        }
        setIsFrameMode(!isFrameMode);
        // Don't clear operation state
        setIsNewInput(true);
        inputRef.current?.focus();
    };

    const calculateResult = (tc1, tc2, op) => {
        if (isFrameMode) {
            const frames1 = parseInt(tc1) || 0;
            const frames2 = parseInt(tc2) || 0;
            const resultFrames = op === 'add' ? frames1 + frames2 : Math.max(0, frames1 - frames2);
            return resultFrames.toString();
        } else {
            const frames1 = typeof tc1 === 'string' && tc1.includes(':') ?
                timecodeToFrames(tc1, frameRate) : parseInt(tc1) || 0;
            const frames2 = typeof tc2 === 'string' && tc2.includes(':') ?
                timecodeToFrames(tc2, frameRate) : parseInt(tc2) || 0;
            const resultFrames = op === 'add' ? frames1 + frames2 : Math.max(0, frames1 - frames2);
            return isFrameMode ? resultFrames.toString() : framesToTimecode(resultFrames, frameRate);
        }
    };

    const handleOperation = (op) => {
        if (runningTotal === null) {
            const currentValue = isFrameMode ? frames : timecode1;
            setRunningTotal(currentValue);
            setDisplayOperation(currentValue);
            if (!isFrameMode) {
                const frm = timecodeToFrames(currentValue, frameRate);
                setFrames(frm.toString());
            }
        } else if (!isNewInput) {
            const currentValue = isFrameMode ? frames : timecode1;
            const result = calculateResult(runningTotal, currentValue, operation);
            setRunningTotal(result);
            setDisplayOperation(displayOperation + ` ${operation === 'add' ? '+' : '−'} ${currentValue}`);
            if (!isFrameMode) {
                const frm = timecodeToFrames(result, frameRate);
                setFrames(frm.toString());
            }
        }

        setOperation(op);
        setIsNewInput(true);
        if (isFrameMode) {
            setFrames('0');
        } else {
            setTimecode1('00:00:00:00');
        }
    };

    const handleEquals = () => {
        if (!operation || runningTotal === null) return;

        const currentValue = isFrameMode ? frames : timecode1;
        const result = calculateResult(runningTotal, currentValue, operation);
        setDisplayOperation(displayOperation + ` ${operation === 'add' ? '+' : '−'} ${currentValue}`);

        if (isFrameMode) {
            setFrames(result);
            setResult(result);
        } else {
            setTimecode1(result);
            setResult(result);
            const resultFrames = timecodeToFrames(result, frameRate);
            setFrames(resultFrames.toString());
        }

        setOperation(null);
        setRunningTotal(null);
        setIsNewInput(true);
    };

    const handleKeyboardOperation = useCallback((e) => {
        if (!inputRef.current?.contains(document.activeElement)) {
            inputRef.current?.focus();
            setActiveInput('tc1');
            return; // Don't process the key if we just focused
        }

        switch (e.key) {
            case '+':
                e.preventDefault();  // Prevent the + from being typed
                handleOperation('add');
                break;
            case '-':
                e.preventDefault();  // Prevent the - from being typed
                handleOperation('subtract');
                break;
            case '=':
            case 'Enter':
                e.preventDefault();  // Prevent newline
                handleEquals();
                break;
            case 'Escape':
                e.preventDefault();
                handleClear();
                break;
            case ' ':  // Space key
                e.preventDefault();
                handleFrameModeToggle();
                break;
            default:
                break;
        }
    }, [handleOperation, handleEquals, handleClear, handleFrameModeToggle]);

    // Update useEffect to use the handler
    useEffect(() => {
        // Set initial focus
        inputRef.current?.focus();
        setActiveInput('tc1');

        window.addEventListener('keydown', handleKeyboardOperation);

        return () => {
            window.removeEventListener('keydown', handleKeyboardOperation);
        };
    }, [handleKeyboardOperation]);

    // Add these handler functions inside the component
    const handleTimecodeInput = (value, setter) => {
        // Remove any non-digits
        const digits = value.replace(/[^\d]/g, '');

        // Take only the last 8 digits (or pad with zeros if less)
        const paddedDigits = digits.slice(-8).padStart(8, '0');

        // Format into timecode
        const formattedTC = [
            paddedDigits.slice(0, 2),
            paddedDigits.slice(2, 4),
            paddedDigits.slice(4, 6),
            paddedDigits.slice(6, 8)
        ].join(':');

        setter(formattedTC);
        setIsNewInput(false);
    };

    const handleAdd = () => {
        const frames1 = timecodeToFrames(timecode1, frameRate);
        const frames2 = timecodeToFrames(timecode2, frameRate);
        const totalFrames = frames1 + frames2;
        setResult(framesToTimecode(totalFrames, frameRate));
    };

    const handleSubtract = () => {
        const frames1 = timecodeToFrames(timecode1, frameRate);
        const frames2 = timecodeToFrames(timecode2, frameRate);
        const totalFrames = Math.max(0, frames1 - frames2);
        setResult(framesToTimecode(totalFrames, frameRate));
    };

    const handleFramesToTC = () => {
        const tc = framesToTimecode(parseInt(frames) || 0, frameRate);
        setTimecode1(tc);
        setResult(tc);
    };

    const handleConversionToggle = () => {
        if (isFrameMode) {
            // If we're in frame mode, convert frames to timecode
            const tc = framesToTimecode(parseInt(frames) || 0, frameRate);
            setTimecode1(tc);
            setResult(tc);
        } else {
            // If we're in timecode mode, convert timecode to frames
            const totalFrames = timecodeToFrames(timecode1, frameRate);
            setFrames(totalFrames.toString());
            setResult(totalFrames.toString());
        }
        setIsFrameMode(!isFrameMode);
    };

    // Handle numeric button clicks
    const handleNumberClick = (num) => {
        if (!activeInput) return;

        if (isFrameMode) {
            const newValue = frames === '0' || isNewInput ? num.toString() : frames + num;
            setFrames(newValue);
            setResult(newValue);
            setIsNewInput(false);
        } else {
            let currentValue = activeInput === 'tc1' ? timecode1 : timecode2;
            if (isNewInput) {
                currentValue = '00:00:00:00';
            }
            const digits = currentValue.replace(/:/g, '');
            const newDigits = (digits + num).slice(-8);
            const newTC = [
                newDigits.slice(0, 2),
                newDigits.slice(2, 4),
                newDigits.slice(4, 6),
                newDigits.slice(6, 8)
            ].join(':');

            if (activeInput === 'tc1') {
                setTimecode1(newTC);
                setResult(newTC);
                const frm = timecodeToFrames(newTC, frameRate);
                setFrames(frm.toString());
            } else {
                setTimecode2(newTC);
            }
            setIsNewInput(false);
        }
    };

    // Add frame rate change handler
    const handleFrameRateChange = (newFrameRate) => {
        // Convert current timecode to seconds
        const sourceFrames = timecodeToFrames(timecode1, frameRate);
        const seconds = sourceFrames / frameRate;

        // Convert seconds to frames at new frame rate
        const targetFrames = Math.round(seconds * newFrameRate);

        // Convert frames to timecode at new frame rate
        const convertedTimecode = framesToTimecode(targetFrames, newFrameRate);

        // Update the display
        setTimecode1(convertedTimecode);
        setResult(convertedTimecode);
        setFrames(targetFrames.toString());

        // Update the frame rate
        setFrameRate(newFrameRate);
    };

    // Add effect to update frames whenever timecode1 changes
    useEffect(() => {
        if (!isNewInput) {
            const frames = timecodeToFrames(timecode1, frameRate);
            setFrames(frames.toString());
        }
    }, [timecode1, frameRate, isNewInput]);

    const handleCopy = () => {
        const textToCopy = isFrameMode ? frames : timecode1;
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        });
    };

    return (
        <div>
            <Segment
                inverted
                textAlign='center'
                style={{ marginBottom: '1em' }}
                vertical
            >
                <Menu
                    fixed='top'
                    inverted
                    pointing={true}
                    secondary={true}
                    size='large'
                    style={{ backgroundColor: "#002b36" }}
                >
                    <Image
                        src={logo}
                        style={{
                            height: "6.5em",
                            paddingLeft: "20px",
                            paddingTop: "10px",
                            cursor: 'pointer'
                        }}
                        onClick={() => history.push('/')}
                    />
                    <Container>
                        <Menu.Item onClick={() => history.push('/')}>Home</Menu.Item>
                        <Menu.Item onClick={() => history.push('/#section1')}>What we do</Menu.Item>
                        <Menu.Item onClick={() => history.push('/#contact')}>Contact</Menu.Item>
                        <Menu.Item style={{ padding: '0 0.8em' }}>
                            <Dropdown
                                text='Tools'
                                pointing
                                className='link item'
                                style={{ height: '100%', display: 'flex', alignItems: 'center' }}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        active
                                        onClick={() => history.push('/tools/timecode-calculator')}
                                    >
                                        Timecode Calculator
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Container>
                </Menu>
            </Segment>
            <Container style={{ marginTop: '7em' }}>
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Segment inverted style={{ backgroundColor: '#111', padding: '1em' }}>
                                <div style={calculatorStyles.displayContainer}>
                                    <div style={{
                                        ...calculatorStyles.display,
                                        transform: isFrameMode ? 'scale(1.02)' : 'scale(1)',
                                        transition: 'transform 0.3s ease'
                                    }}>
                                        <Button
                                            icon
                                            style={calculatorStyles.copyButton}
                                            onClick={handleCopy}
                                            title={copySuccess ? 'Copied!' : 'Copy to clipboard'}
                                        >
                                            <Icon name={copySuccess ? 'check' : 'copy outline'} />
                                        </Button>
                                        {(operation || displayOperation) && (
                                            <div style={calculatorStyles.operationDisplay}>
                                                {operation && !isNewInput ?
                                                    `${displayOperation} ${operation === 'add' ? '+' : '−'} ${isFrameMode ? frames : timecode1}` :
                                                    displayOperation
                                                }
                                            </div>
                                        )}
                                        <input
                                            ref={inputRef}
                                            type="text"
                                            value={isFrameMode ? frames : (activeInput === 'tc1' ? timecode1 : (activeInput === 'tc2' ? timecode2 : result))}
                                            onChange={(e) => handleTimecodeInput(e.target.value, activeInput === 'tc1' ? setTimecode1 : setTimecode2)}
                                            onFocus={() => setActiveInput('tc1')}
                                            style={calculatorStyles.input}
                                        />
                                    </div>
                                </div>

                                <div style={calculatorStyles.secondaryDisplay}>
                                    <span>{isFrameMode ? `${frames} FRM` : `${timecode1} ${frameRate} FPS`}</span>
                                    <span>{isFrameMode ? `TC: ${framesToTimecode(parseInt(frames) || 0, frameRate)}` : `${frames} FRM`}</span>
                                </div>

                                <Grid style={{ marginTop: '0.3em' }}>
                                    <Grid.Row columns={4} style={{ marginBottom: '0.1em' }}>
                                        <Grid.Column>
                                            <Button
                                                fluid
                                                style={calculatorStyles.button}
                                                onClick={handleClear}
                                            >
                                                AC
                                            </Button>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Button
                                                fluid
                                                style={calculatorStyles.modeToggleButton}
                                                onClick={handleFrameModeToggle}
                                            >
                                                <div style={calculatorStyles.modeLabel}>MODE:</div>
                                                <div style={calculatorStyles.modeValue}>
                                                    <span style={{ color: isFrameMode ? '#dc322f' : '#268bd2' }}>
                                                        {isFrameMode ? 'FRAMES' : 'TIMECODE'}
                                                    </span>
                                                </div>
                                            </Button>
                                        </Grid.Column>
                                        <Grid.Column>
                                            {/* Removed TC÷ button */}
                                        </Grid.Column>
                                        <Grid.Column>
                                            {/* Empty column */}
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={4} style={{ marginBottom: '0.1em' }}>
                                        {[7, 8, 9].map(num => (
                                            <Grid.Column key={num}>
                                                <Button
                                                    fluid
                                                    style={calculatorStyles.button}
                                                    onClick={() => handleNumberClick(num)}
                                                >
                                                    {num}
                                                </Button>
                                            </Grid.Column>
                                        ))}
                                        <Grid.Column>
                                            <Button
                                                fluid
                                                style={calculatorStyles.operatorButton}
                                                onClick={() => handleOperation('subtract')}
                                            >
                                                −
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={4} style={{ marginBottom: '0.1em' }}>
                                        {[4, 5, 6].map(num => (
                                            <Grid.Column key={num}>
                                                <Button
                                                    fluid
                                                    style={calculatorStyles.button}
                                                    onClick={() => handleNumberClick(num)}
                                                >
                                                    {num}
                                                </Button>
                                            </Grid.Column>
                                        ))}
                                        <Grid.Column>
                                            <Button
                                                fluid
                                                style={calculatorStyles.operatorButton}
                                                onClick={() => handleOperation('add')}
                                            >
                                                +
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row style={{ marginBottom: '0.1em' }}>
                                        <Grid.Column width={12}>
                                            <Grid>
                                                <Grid.Row columns={3} style={{ marginBottom: '0.1em' }}>
                                                    {[1, 2, 3].map(num => (
                                                        <Grid.Column key={num}>
                                                            <Button
                                                                fluid
                                                                style={calculatorStyles.button}
                                                                onClick={() => handleNumberClick(num)}
                                                            >
                                                                {num}
                                                            </Button>
                                                        </Grid.Column>
                                                    ))}
                                                </Grid.Row>
                                                <Grid.Row columns={2}>
                                                    <Grid.Column width={10}>
                                                        <Button
                                                            fluid
                                                            style={calculatorStyles.button}
                                                            onClick={() => handleNumberClick(0)}
                                                        >
                                                            0
                                                        </Button>
                                                    </Grid.Column>
                                                    <Grid.Column width={6}>
                                                        <Button
                                                            fluid
                                                            style={calculatorStyles.button}
                                                            onClick={() => handleNumberClick('00')}
                                                        >
                                                            00
                                                        </Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Button
                                                fluid
                                                style={{
                                                    ...calculatorStyles.equalsButton,
                                                    height: '6.5em'
                                                }}
                                                onClick={handleEquals}
                                            >
                                                =
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                <Grid style={{ marginTop: '0.8em' }}>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <div style={{ color: '#666', marginBottom: '0.3em' }}>Frame Rate</div>
                                            <Dropdown
                                                fluid
                                                selection
                                                options={frameRateOptions}
                                                value={frameRate}
                                                onChange={(e, { value }) => handleFrameRateChange(value)}
                                                style={{
                                                    backgroundColor: '#1a1a1a',
                                                    color: '#fff',
                                                    border: 'none',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Segment inverted style={{ backgroundColor: '#111', padding: '1.5em' }}>
                                <Header as='h3' inverted>Instructions</Header>
                                <List inverted relaxed>
                                    <List.Item>
                                        <List.Icon name='keyboard' />
                                        <List.Content>
                                            <List.Header>Timecode Input</List.Header>
                                            <List.Description>
                                                Enter numbers to build timecode (HH:MM:SS:FF)<br />
                                                Numbers roll over automatically<br />
                                                AC button clears the input
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Icon name='calculator' />
                                        <List.Content>
                                            <List.Header>Basic Operations</List.Header>
                                            <List.Description>
                                                Add timecodes using + button<br />
                                                Subtract timecodes using - button<br />
                                                Press = or Enter to calculate
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Icon name='sync' />
                                        <List.Content>
                                            <List.Header>Mode Switch</List.Header>
                                            <List.Description>
                                                Use MODE button to switch between:<br />
                                                • TIMECODE (HH:MM:SS:FF format)<br />
                                                • FRAMES (total frame count)<br />
                                                Switch anytime during calculations
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Icon name='clock' />
                                        <List.Content>
                                            <List.Header>Frame Rate Conversion</List.Header>
                                            <List.Description>
                                                1. Enter your timecode<br />
                                                2. Select new frame rate from dropdown<br />
                                                3. Timecode converts automatically<br />
                                                Common conversions:<br />
                                                • Film (24) ↔ PAL (25)<br />
                                                • PAL (25) ↔ NTSC (29.97)<br />
                                                • Film (24) ↔ NTSC (29.97)
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                    <List.Item>
                                        <List.Icon name='keyboard outline' />
                                        <List.Content>
                                            <List.Header>Keyboard Shortcuts</List.Header>
                                            <List.Description style={{ lineHeight: '1.5em' }}>
                                                <strong>Numbers:</strong> 0-9 for direct input<br />
                                                <strong>Operations:</strong><br />
                                                • <code>+</code> Add timecodes<br />
                                                • <code>-</code> Subtract timecodes<br />
                                                • <code>Enter</code> or <code>=</code> Calculate result<br />
                                                <strong>Clear/Cancel:</strong><br />
                                                • <code>Esc</code> Clear current input<br />
                                                • <code>Delete</code> or <code>Backspace</code> Clear all<br />
                                                <strong>Navigation:</strong><br />
                                                • <code>Tab</code> Move between inputs<br />
                                                • <code>Space</code> Toggle mode
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    );
}

export default TimecodeCalculator; 