import { createMedia } from '@artsy/fresnel'
import React, { Fragment, useState } from 'react'
import {
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
    Dropdown
} from 'semantic-ui-react'
import { Link as ScrollLink } from "react-scroll";
import { useHistory } from "react-router-dom";
import desk from "../../images/desk_cropped.jpg";
import logo from "../../images/logo light horiz.png";

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

const HomepageHeading = ({ mobile }) => (
    <Fragment>
        <Image
            id="home"
            fluid
            src={desk}
        />
        <Header
            as='h1'
            content='"...intelligent solutions for broadcasters"'
            className='header-text'
            inverted
            style={{
                position: "absolute",
                top: "0",
                left: "0",
                textShadow: '3px 3px 3px #657b82',
                fontSize: mobile ? '2em' : '4em',
                fontWeight: 'normal',
                marginTop: mobile ? '3em' : '3em',
                marginLeft: mobile ? '0.5em' : '3em',
                marginRight: mobile ? '0.5em' : '0'
            }}
        />
    </Fragment>
)

const ScrollMenuItem = ({ title, target, offset }) => (
    <Menu.Item
        as={ScrollLink}
        activeClass="active"
        to={target}
        spy={true}
        smooth={true}
        offset={offset || 0}
        duration={100}>
        {title}
    </Menu.Item>);

const DesktopContainer = ({ children }) => {
    const history = useHistory();

    return (
        <Media greaterThan='mobile'>
            <Segment
                inverted
                textAlign='center'
                style={{ paddingTop: '6em', marginBottom: '1em' }}
                vertical
            >
                <Menu
                    fixed='top'
                    inverted
                    pointing={true}
                    secondary={true}
                    size='large'
                    style={{ backgroundColor: "#002b36" }}
                >
                    <Image src={logo} style={{
                        height: "6.5em",
                        paddingLeft: "20px",
                        paddingTop: "10px"
                    }} />
                    <Container>
                        <ScrollMenuItem title='Home' target='home' offset={-95} />
                        <ScrollMenuItem title='What we do' target='section1' offset={-95} />
                        <ScrollMenuItem title='Contact' target='contact' offset={-95} />
                        <Menu.Item style={{ padding: '0 0.8em' }}>
                            <Dropdown
                                text='Tools'
                                pointing
                                className='link item'
                                style={{ height: '100%', display: 'flex', alignItems: 'center' }}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => history.push('/tools/timecode-calculator')}
                                    >
                                        Timecode Calculator
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Container>
                </Menu>
                <HomepageHeading />
            </Segment>
            {children}
        </Media>
    )
}


const MobileContainer = ({ children }) => {
    const history = useHistory();
    const [sidebarOpened, setSidebarOpened] = useState(false);
    const handleSidebarHide = () => setSidebarOpened(false);
    const handleToggle = () => setSidebarOpened(true);

    return (
        <Media as={Sidebar.Pushable} at='mobile'>
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted
                    onHide={handleSidebarHide}
                    vertical
                    visible={sidebarOpened}
                >
                    <ScrollMenuItem title='Home' target='home' />
                    <ScrollMenuItem title='What we do' target='section1' />
                    <ScrollMenuItem title='Contact' target='contact' />
                    <Menu.Item
                        onClick={() => {
                            history.push('/tools/timecode-calculator');
                            handleSidebarHide();
                        }}
                    >
                        Timecode Calculator
                    </Menu.Item>
                </Sidebar>

                <Sidebar.Pusher dimmed={sidebarOpened}>
                    <Segment
                        inverted
                        textAlign='center'
                        style={{ minHeight: 350, padding: '1em 0em' }}
                        vertical
                    >
                        <Container>
                            <Menu inverted pointing secondary size='large'>
                                <Menu.Item onClick={handleToggle}>
                                    <Icon name='sidebar' />
                                </Menu.Item>
                                <Image src={logo} size='tiny' />
                            </Menu>
                        </Container>
                        <HomepageHeading mobile />
                    </Segment>
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Media>
    )
}


const ResponsiveContainer = ({ children }) => (
    <MediaContextProvider>
        <DesktopContainer>{children}</DesktopContainer>
        <MobileContainer>{children}</MobileContainer>
    </MediaContextProvider>
)

export const HomepageLayout = () => {

    return (
        <ResponsiveContainer>
            <Segment style={{ padding: '8em 0em' }} vertical id="section1">
                {/*<Header as='h1' textAlign='center' style={{fontSize: '3em'}}>Services</Header>*/}
                {/*<Divider/>*/}
                <Grid columns={3} container divided stackable verticalAlign='top'>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>
                            <Header as='h2' icon inverted>
                                <Icon name='code' style={{ color: '#268bd2' }} />
                                Software Solutions
                                <Divider />
                                <Header.Subheader>
                                    API | UI | Middleware | Automation
                                </Header.Subheader>
                            </Header>
                            <Divider />
                            <p style={{ fontSize: '1.33em', textAlign: 'centre' }}>
                                We have designed, written, and delivered numerous bespoke software solutions to major UK
                                broadcasters. From large scale distributed content supply systems to small one-off
                                software solutions to get the job done. From data migrations to API integrations,
                                KnightTV can help you.
                            </p>
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            <Header as='h2' icon inverted>
                                <Icon name='lightbulb outline' style={{ color: '#cb4b16' }} />
                                Design
                                <Divider />
                                <Header.Subheader>
                                    Software | UI | Workflow | Systems
                                </Header.Subheader>
                            </Header>
                            <Divider />
                            <p style={{ fontSize: '1.33em' }}>
                                Workflows, software, supply chain design, and consultancy services.
                                We can supercharge your workflows and help you get the most out of your systems.

                            </p>
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            <Header as='h2' icon inverted>
                                <Icon name='video' style={{ color: '#859900' }} />
                                Video
                                <Divider />
                                <Header.Subheader>
                                    Transcode | Playout | MAM
                                </Header.Subheader>
                            </Header>
                            <Divider />
                            <p style={{ fontSize: '1.33em' }}>
                                Knight TV can help you with your video needs. From transcoding to playout, we have
                                experience in all aspects of video. We can help you with your video workflows, and
                                integrate your systems to get the most out of your video assets.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment style={{ padding: '8em 0em' }} vertical id="blog">
                <Container fluid>
                    <Divider
                        as='h4'
                        className='header'
                        horizontal
                        style={{ margin: '3em 0em', textTransform: 'uppercase' }}
                    >
                        <a href='#'>Testimonials</a>
                    </Divider>
                    <Grid celled='internally' columns='equal' stackable>
                        <Grid.Row textAlign='center'>
                            <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                                <Header as='h3' style={{ fontSize: '2em' }} inverted>
                                    <Grid columns='3' >
                                        <Grid.Column><Icon size="big" name="quote left" /></Grid.Column>
                                        <Grid.Column>An invaluable asset to any Broadcast organisation</Grid.Column>
                                        <Grid.Column verticalAlign="bottom"><Icon size="big" name="icon quote right" /></Grid.Column>
                                    </Grid>
                                </Header>
                                <i><p style={{ fontSize: '1.33em' }}>Barney MacDonald | Roger Roger TV</p></i>
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                                <Header as='h3' style={{ fontSize: '2em' }} inverted>
                                    <Grid columns='3' >
                                        <Grid.Column><Icon size="big" name="quote left" /></Grid.Column>
                                        <Grid.Column>Dan is one of most capable people I have ever worked with</Grid.Column>
                                        <Grid.Column verticalAlign="bottom"><Icon size="big" name="icon quote right" /></Grid.Column>
                                    </Grid>
                                </Header>
                                <p style={{ fontSize: '1.33em' }}></p>
                                <i><p style={{ fontSize: '1.33em' }}>Kevin Coyne | Croft Systems Ltd</p></i>
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                </Container>
            </Segment>
            <Segment style={{ padding: '8em 0em' }} vertical id="contact">
                <Container text textAlign="center">
                    <Divider
                        as='h4'
                        className='header'
                        horizontal
                        style={{ margin: '3em 0em', textTransform: 'uppercase' }}
                    >
                        <a href='#'>Contact Us</a>
                    </Divider>

                    <p style={{ fontSize: '1.33em' }}>
                        <Icon name="mail" /><a
                            href="mailto: enquiries@knightknight.io?subject=Knight TV Enquiry"> enquiries@knightknight.io</a>
                    </p>

                    <p style={{ fontSize: '1.33em' }}>
                        <Icon name="phone" />
                        + 44 (0) 7768 034444
                    </p>
                    <p style={{ fontSize: '1.33em' }}>
                        <a href="https://www.linkedin.com/company/knight-tv">
                            <Icon size="large" name="linkedin" />
                        </a>
                    </p>
                </Container>
            </Segment>
            <Segment
                inverted
                vertical
                style={{
                    padding: '1em 0em',
                    marginTop: '1em',
                    textAlign: 'center'
                }}
            >
                <p>
                    <Icon name='heart' color='red' /> Like this website? It was designed and written in React by Knight
                    TV Ltd |
                    <a href='mailto: enquiries@knightknight.io'> Contact Us</a>
                </p>
            </Segment>
        </ResponsiveContainer>
    )
}